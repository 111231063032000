
import { Vue, Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { RelatorioService } from '@/core/services/financeiro';
 
@Component
export default class PainelReceita extends mixins(Vue, ListPage) {
    item:any = {};
    isLoading: boolean = false;
    service = new RelatorioService()


    breadCrumbLinks: any[] = [
        { text: 'Home', href: '/financeiro/painel/kpi'},
        { text: 'Transfêrencias', disabled: true, href: '/split/transferencia'}
    ]

    items: any = [
        { dataCriacao: '01/01/2024', recebedor:'BARTOLOMEU', chave:'52185455215-18', status:'TRANSFERIDA',  valor: 5000 },
        { dataCriacao: '01/02/2024', recebedor:'BEATRIZ', chave:'545451484554-5151', status:'TRANSFERIDA',  valor: 5000 },
        { dataCriacao: '01/03/2024', recebedor:'ANA ', chave:'15152154121-25', status:'TRANSFERIDA',  valor: 5000 },
        { dataCriacao: '01/04/2024', recebedor:'FERNANDO', chave:'1554858521-04', status:'TRANSFERIDA',  valor: 5000 },
        { dataCriacao: '01/05/2024', recebedor:'MARIA', chave:'2115151515151', status:'ABERTO',  valor: 5000 },
    ]
 
    headersTransferencia: any[] = [
        { text: 'CRIADO EM', align: 'center', value: 'dataCriacao' },
        { text: 'RECEBEDOR', align: 'center', value: "recebedor" },
        { text: 'CHAVE PIX', align: 'center', value: "chave" },
        { text: 'STATUS', align: 'center', value: "status" },
        { text: 'VALOR', align: 'center', value: "valor" }
    ];

    loading: boolean = false;
    dialogCadastro: boolean = false;
    errorAlert = false;

    titulo: string = 'Painel de receita';
    subTitulo: string = 'Receitas';

    options: any = {
        itemsPerPage: 15
    };

    headers: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: 'Nome', value: 'nome' }
    ];

    colorStatus(item){
        if(item.status == 'TRANSFERIDA'){
            return '#90CAF9'
        }
    }
     
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
         // this.$toast.success("Conta copiada para a área de transferência!");
         this.errorAlert = true;
        },
        (err) => {
          console.error("Erro ao copiar:", err);
        }
      );
    }

    mounted() {
        
    }
}
